<nz-table #table [nzData]="data" [nzTotal]="data_count" [nzShowPagination]="true" [nzPageSize]="pageSize"
    [nzPageIndex]="pagination.index + 1" [nzFrontPagination]="frontPagination"
    (nzPageIndexChange)="onPageChange($event)" [nzScroll]="{x: scrollable ? '900px' : null}">

    <!-- <nz-pagination [nzPageIndex]="pagination.index + 1" [nzTotal]="data_count"></nz-pagination> -->

    <thead>
        <tr>
            <th *ngFor="let column of columns" [nzWidth]="column.width ? column.width : '250px'">
                <div [ngClass]="{'clickable': column.sortable}" (click)="onSortChange(column)">
                    <span>{{ column.name}}</span>

                    <span *ngIf="column.sortable && sort.key == column.key" [ngClass]="{'ms-2': sort.direction}">
                        <i *ngIf="sort.direction == 'asc'" class="las la-angle-up"></i>
                        <i *ngIf="sort.direction == 'desc'" class="las la-angle-down"></i>
                    </span>
                </div>
            </th>

            <th *ngIf="actions.length > 0 || row_actions" [nzWidth]="actions.length > 2 ? '170px' :' 150px'">
                <div class="d-flex justify-content-between align-items-center">
                    <span>{{'Actions'}}</span>
                    <span *ngIf="editable_columns">
                        <i class="las la-cog gear-icon"></i>
                    </span>
                </div>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let data of table.data" [ngClass]="{'clickable': clickable}" (click)="onRowClick(data)">

            <td *ngFor="let column of columns" [nzEllipsis]="false">
                <div>
                    {{data[column.key]}}
                </div>
            </td>

            <td *ngIf="actions.length > 0">
                <div *ngFor="let action of actions; last as last; first as first"
                    (click)="onActionClick(action, $event, data)">
                    <ng-container [ngTemplateOutlet]="actionsColumn"
                        [ngTemplateOutletContext]="{action: action, data: data, last: last}">
                    </ng-container>
                </div>
            </td>

            <td *ngIf="row_actions">
                <div *ngFor="let action of data.actions; last as last; first as first"
                    (click)="onActionClick(action, $event, data)">
                    <ng-container [ngTemplateOutlet]="actionsColumn"
                        [ngTemplateOutletContext]="{action: action, data: data, last: last}">
                    </ng-container>
                </div>
            </td>
        </tr>
    </tbody>

</nz-table>

<ng-template #actionsColumn let-action='action' let-data='data' let-last='last'>
    <div [ngSwitch]="action" [ngClass]="{'me-1': !last}">

        <span *ngSwitchCase="'detail'" class="action">
            <i class="las la-eye"></i>
        </span>

        <span *ngSwitchCase="'approve'" class="action">
            <i class="las la-check"></i>
        </span>

        <span *ngSwitchCase="'reject'" class="action">
            <i class="las la-times"></i>
        </span>

        <span *ngSwitchCase="'edit'" class="action">
            <span nz-icon nzType="edit" nzTheme="outline"></span>
        </span>

        <span *ngSwitchCase="'add'" class="action">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
        </span>

        <span *ngSwitchCase="'choose'" class="action">
            <i class="las la-arrow-right"></i>
        </span>

        <span *ngSwitchCase="'process'" class="action-png">
            <img class="png" src="../../../assets/img/take_charge.png">
        </span>

        <span *ngSwitchCase="'search_availability'">
            <button nz-button nzType="primary" class="d-flex justify-content align-items-center">
                <i class="las la-search-location me-2" style="font-size: 1.4rem"></i>
                <span>Ricerca disponibilità</span>
            </button>
        </span>

        <span *ngSwitchCase="'arrow_up'" class="action">
            <i class="las la-arrow-up"></i>
        </span>

        <span *ngSwitchCase="'arrow_down'" class="action">
            <i class="las la-arrow-down"></i>
        </span>

        <!-- <span nzTrigger="click" nz-button nzPlacement="bottomRight" [nzDropdownMenu]="menu" nz-dropdown
            *ngSwitchCase="'dropdown'" class="action">
            <i class="las la-ellipsis-h"></i>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                    <li *ngFor="let option of dropdownMenu" nz-menu-item
                        (click)="onActionClick(action, $event, data, option)">
                        {{option | translate}}
                    </li>
                </ul>
            </nz-dropdown-menu>
        </span> -->
    </div>
</ng-template>