import {Component, Input, OnInit, EventEmitter, Output, HostListener} from '@angular/core';
import {Router} from "@angular/router";
import {Card} from "../../shared/models/card.model";

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent implements OnInit {
  isCardListView: boolean = window.innerWidth> 820;
  @Input() data?: Card;
  @Output() actionButtonEvent = new EventEmitter<string>();

  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.updateView();
    }

  allowedPosition : string[] = ['left', 'right'];

  constructor(private _router: Router) { }

  ngOnInit() {
    // console.log('data', this.data);

    if(!this.allowedPosition.includes(<string>this.data?.imagePosition)) {
      this.data!.imagePosition = 'left';
    }
  }

  action(){
    // if(this.data?.buttonAction){
    //   this.data?.buttonAction();
    // }
    // this.actionButtonEvent.emit('');

  }

  private updateView(): void {
    this.isCardListView = window.innerWidth > 1200;
}
}

