import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./modules/auth/components/login/login.component";
import {UserChoiceComponent} from "./modules/application/components/user-choice/user-choice.component";
import {AuthGuard} from "./shared/auth.guard";

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/modules/application/application.module').then(m => m.ApplicationModule), /*canActivate: [AuthGuard]*/ },
  { path: '',   redirectTo: 'auth', pathMatch: 'full' },
  { path: '*',   redirectTo: '' },
  // { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
