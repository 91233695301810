import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

export interface TableColumn {
  name: string;
  key: string;
  sortable?: boolean;
  type?: string;
  width?: string;
}

export interface TableSort {
  key: string;
  direction: string;
}

export interface TablePagination {
  size: number;
  index: number;
}

export interface TableFilters {
  label: string;
  key: string;
  type: string;
  info?: any;
}

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {

  @Input() columns : TableColumn[] = [];
  @Input() availableColumns : TableColumn[] = [];
  @Input() data : any[] = [];
  @Input() data_count : number = 0;
  @Input() actions : any[] = [];
  @Input() clickable : boolean = false;
  @Input() row_actions : boolean = false;
  @Input() pageSize : number = 4;
  @Input() frontPagination : boolean = false;
  @Input() allowClearSelect : boolean = true;
  @Input() showFilters : boolean = true;
  @Input() scrollable : boolean = false;
  @Input() editable_columns : boolean = false;

  @Output() page_changed : EventEmitter<TablePagination> = new EventEmitter<TablePagination>();
  @Output() sort_changed : EventEmitter<TableSort> = new EventEmitter<TableSort>();
  @Output() row_clicked : EventEmitter<any> = new EventEmitter<any>();
  @Output() action_clicked : EventEmitter<any> = new EventEmitter<any>();

  pagination : TablePagination = {size: 4, index: 0};
  sort : TableSort = {key: '', direction: ''};

  ngOnInit(): void {
    console.log('Da Datatable',this.data_count);
    
  }

  /**
   * Al cambio pagina aggiorno l'indice della paginazione
   * @param index indice corrente
   */
  onPageChange(index: any) {
    console.log(index);
    
    this.pagination.index = index - 1;
    this.page_changed.emit(this.pagination);
  }

  onSortChange(column: TableColumn) {
    if(column.sortable){

      //se nessuna key è impostata oppure se è diversa dalla colonna cliccata => la imposto con direzione ascendente
      if(this.sort.key == '' || (this.sort.key != '' && this.sort.key != column.key)){
        this.sort.key = column.key;
        this.sort.direction = 'asc';
      }

      //se la key impostata è la stessa che è stata cliccata => la imposto con direzione discendente
      else if(this.sort.key == column.key && this.sort.direction == 'asc'){
        this.sort.direction = 'desc';
      }

      //se la key impostata è la stessa che è stata cliccata e ha direzione discendente => resetto il sorting
      else if(this.sort.key == column.key && this.sort.direction == 'desc'){
        this.sort.key = '';
        this.sort.direction = '';
      }

      this.pagination.index = 0;
      this.sort_changed.emit(this.sort);
    }
  }
  
 /**
   * Al click sulla riga se l'opzione clickable è true invio un evento al componente padre
   * @param data informazioni sulla riga cliccata
   */
 onRowClick(data: any) {
  if(this.clickable) this.row_clicked.emit(data);
}

/**
   * Al click su una delle azioni invio un evento al componente padre
   * @param action stringa dell'azione cliccata
   * @param event evento click
   */
onActionClick(action: any, event: any, row: any, sub_action = null) {
  //serve per evitare che cliccando sull'azione si triggeri il click sulla riga
  event.stopPropagation();

  this.action_clicked.emit({action: action, data: row, sub_action: sub_action});
}

}
