import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private locations = new BehaviorSubject<any>(null);
  locations$ = this.locations.asObservable();

  constructor(private http: HttpClient, private _cookie: CookieService) {
    this.locations$.subscribe({
      next: (value) => {
        if (value === null) {
          this.getLocationsGeneric();
        } else {
          // console.log('The value is not null:', value);
        }
      },
      error: (error) => {
        console.error('Error:', error);
      },
      complete: () => {
        console.log('Observable completed');
      },
    });
  }

  setLocations(locations: any): void {
    this.locations.next(locations);
  }

  /**
   * recupera una lista di news
   */
  getLocations(status: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/locations-list?status=${status}`
    );
  }

  /**
   * recupera una lista di proprietà con stato attivo
   */
  getLocationsGeneric(): Observable<any> {
    return this.http.get<any>(`${environment.api}/locations-list`);
  }
}
