<h4>Appartamenti Censiti</h4>
<div class="desk" *ngIf="isCardListView && request.length > 0">
  <div class="row px-0 my-5">
    <ng-container *ngFor="let el of request">
      <div *ngIf="request.length > 3" class="col-12 p-2"
        [ngClass]="{'col-xl' : request.length<4, 'col-xl-3' : request.length>3}">
        <div class="card">
          <div [class]="'' + el?.style" style="margin-bottom: 20px;">
            <!-- <img *ngIf="el?.imagePath" class="card-img img-fluid" [src]="'../assets/imgs/'+el.imagePath"> -->
            <img class="card-img img-fluid" style="height: 12rem" [src]="'/assets/imgs/sofa.svg'">
            <!--          <p *ngIf="el.title" class="card-title">{{el.title}}</p>-->
            <p *ngIf="el.subtitle" class="card-subtitle">{{el.subtitle}}</p>
          </div>

          <div class=" align-items-center icons-container">
            <div class="d-flex align-items-center">
              <span nz-icon class="icon">
                <span *ngIf="el?.bed"><strong>{{el?.bed}}</strong></span>
                <img class="img m-2" [src]="'/assets/imgs/bed_icon.svg'">
              </span>
              <span nz-icon *ngIf="el?.parking > 0" class="icon">
                <span><strong>{{el?.parking ? 'Sì' : 'No'}}</strong></span>
                <img class="img m-2" [src]="'/assets/imgs/parking_icon.svg'">
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="request.length <= 3" [ngClass]="{'col-xl' : request.length<4, 'col-xl-3' : request.length>3}">

        <div class="card" style="max-width: 30rem;">
          <div [class]="'' + el?.style" style="margin-bottom: 20rwh;">
            <!-- <img *ngIf="el?.imagePath" class="card-img img-fluid" [src]="'../assets/imgs/'+el.imagePath"> -->
            <img class="card-img img-fluid" style="height: 12rem" [src]="'/assets/imgs/sofa.svg'">
            <!--          <p *ngIf="el.title" class="card-title">{{el.title}}</p>-->
            <p *ngIf="el.subtitle" class="card-subtitle">{{el.subtitle}}</p>
          </div>

          <div class=" align-items-center icons-container">
            <div class="d-flex align-items-center">
              <span nz-icon class="icon">
                <span *ngIf="el?.bed"><strong>{{el?.bed}}</strong></span>
                <img class="img m-2" [src]="'/assets/imgs/bed_icon.svg'">
              </span>
              <span nz-icon *ngIf="el?.parking > 0" class="icon">
                <span><strong>{{el?.parking ? 'Sì' : 'No'}}</strong></span>
                <img class="img m-2" [src]="'/assets/imgs/parking_icon.svg'">
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<div class="mobile" *ngIf="!isCardListView && request.length > 0">
  <nz-carousel [nzEffect]="effect">
    <div nz-carousel-content *ngFor="let item of request">
      <div class="container-list" style="margin-right: 20px;">
        <div class="card col">
          <div class="d-flex flex-column align-items-center text overflow-hidden">
            <img class="img m-2" style="height: 12rem" [src]="'/assets/imgs/sofa.svg'">
            <div class="flex-column align-items-center container-data">

              <span>
                <p *ngIf="item?.title" class="title my-1">{{item?.title}}</p>
              </span>
              <span>
                <p *ngIf="item?.status"> {{item?.status}} </p>
              </span>
              <hr>
              <div class=" align-items-center icons-container">
                <div class="d-flex align-items-center">
                  <span nz-icon class="icon">
                    <span *ngIf="item?.bed"><strong>{{item?.bed}}</strong></span>
                    <img class="img m-2" [src]="'/assets/imgs/bed_icon.svg'">
                  </span>
                  <span nz-icon *ngIf="item?.parking > 0" class="icon">
                    <span><strong>{{item?.parking ? 'Sì' : 'No'}}</strong></span>
                    <img class="img m-2" [src]="'/assets/imgs/parking_icon.svg'">
                  </span>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </nz-carousel>
</div>
<ng-container *ngIf="isCardListView && request.length === 0; else noPropertiesTemplate">
  <div class="no-properties-container">
    <h6>Nessun appartamento disponibile.</h6>
  </div>
</ng-container>

<ng-template #noPropertiesTemplate>
  <div class="no-properties-container">
    <!-- <h5>Nessun appartamento disponibile.</h5> -->
  </div>
</ng-template>