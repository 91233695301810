import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorService } from './shared/helpers/interceptor.service';
import { ComponentsModule } from './components/components.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthService } from './shared/services/auth.service';
import { Observable, tap } from 'rxjs';
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NzTransitionPatchModule } from 'ng-zorro-antd/core/transition-patch/transition-patch.module';
import { NgZorroAntdModule } from './components/ng-zorro-antd/ng-zorro-antd.module';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { CookieService } from 'ngx-cookie-service';
import { NzSpinModule } from 'ng-zorro-antd/spin';

registerLocaleData(it);

function initializeAppFactory(auth: AuthService): () => Observable<any> {
  // Get logged user if exists
  return () =>
    auth.getUser(true).pipe(
      tap((response) => {
        auth.currentUser.next(response?.data);
      })
    );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    FormsModule,
    NgZorroAntdModule,
    NzCarouselModule,
    NzSpinModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: CookieService,
    },
    /*{
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true,
    },*/
    { provide: NZ_I18N, useValue: it_IT },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
