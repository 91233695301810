<h4 *ngIf="title">{{title}}</h4>
<div *ngIf="isCardListView">
  <div class="row my-5" *ngIf="elements.length>0">
    <ng-container *ngFor="let el of elements">
      <div class="col-12 p-2" [ngClass]="{'col-xl' : elements.length<4, 'col-xl-3' : elements.length>3}">
        <div [class]="'' + el.style">
          <img *ngIf="el.imagePath" class="card-img img-fluid" [src]="getImagePath(el)">
          <p *ngIf="el.title" class="card-title">{{el.title}}</p>
          <p *ngIf="el.subtitle" class="card-subtitle">{{el.subtitle}}</p>
          <div class="pb-2 px-3 mt-5" *ngIf="el?.button" (click)="el?.buttonAction(el)">
            <a class="a-news" >
              {{el?.button}}
              <span *ngIf="el && el.buttonIcon" nz-icon [nzType]="el.buttonIcon" class="icon-news"></span>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<div *ngIf="!isCardListView">
  <nz-carousel [nzEffect]="effect">
    <div nz-carousel-content *ngFor="let el of elements">
      <div class="p-2" class="col-12" style="padding: 15%;"
        [ngClass]="{'col-xl' : elements.length<4, 'col-xl-3' : elements.length>3}">
        <div [class]="'' + el.style">
          <img *ngIf="el.imagePath" class="card-img img-fluid" [src]="'../assets/imgs/'+el.imagePath">
          <p *ngIf="el.title" class="card-title">{{el.title}}</p>
          <p *ngIf="el.subtitle" class="card-subtitle">{{el.subtitle}}</p>
          <div class="pb-1 px-1 mt-1" *ngIf="el?.button" (click)="el?.buttonAction(el)">
            <a>
              {{el?.button}}
              <span *ngIf="el && el.buttonIcon" nz-icon [nzType]="el.buttonIcon" class="icon"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nz-carousel>
</div>
