<div class="d-flex flex-column flex-xl-row justify-content-xl-between my-5">
  <!-- <img class="img" [src]="'../../assets/imgs/news.svg'"> -->
  <!-- la versione commentata dell' img serve a usare un immagine dai nostri assets ,
    recuperando tramite feed è possibile utilizzare questa seconda versione -->
  <img class="img" [src]="news2?.image" alt="News Image">
  <div class="title py-4">{{news2?.title}}</div>
</div>

<div class="divider-top justify-content-center"></div>

<div class="justify-content-xl-between my-5">
  <h4>Descrizione</h4>
  <!-- <div class="desc">{{news2?.description}}</div> -->
  <div class="desc">
    <p class="p-news-feed" [innerHTML]="decodeEntities(news2?.description)"></p>
    <img *ngIf="news2?.image" class="img-news-feed" [src]="news2?.image" alt="Image">
  </div>
</div>

<app-jumbotron [data]="contattaci"></app-jumbotron>
