import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Card } from 'src/app/shared/models/card.model';
import { UserService } from 'src/app/shared/helpers/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-requestSuccessfully',
  templateUrl: './requestSuccessfully.component.html',
  styleUrls: ['./requestSuccessfully.component.scss'],
})
export class RequestSuccessfullyComponent implements OnInit {
  user_type = localStorage.getItem('user_type') ?? 'requestor';
  user_id: string = '';
  destroy$: Subject<void> = new Subject<void>();

  customHtml: string =
    '<p class="col-12 titolo py-0 my-0"><span class="titolo-red">Richiesta</span></p><span class="col-12 titolo d-block mb-5">inoltrata correttamente </span>';
  customSubtitle: string =
    '<p class="col-12 sub-title py-0 my-0">Lorem ipsum dolor sit amet consectetur. Odio viverra sit eleifend lectus neque.</span>';
  contattaciTitleHtml: string =
    '<p class="col-12 titolo py-0 my-0">Hai bisogno di aiuto?</p><span class="col-12 titolo d-block mb-5"><span class="titolo-red">contattaci!</span></span>';

  jumbo: Card = {
    imagePath: 'successfull.svg',
    imagePosition: 'right',
    title: this.customHtml,
    subtitle: this.customSubtitle,
    button: 'Torna alla home',
    buttonIcon: 'home',
    buttonAction: () => this.goTo(),
  };

  contattaci: Card = {
    imagePath: 'contattaci.svg',
    imagePosition: 'left',
    title: this.contattaciTitleHtml,
    subtitle: this.customSubtitle,
    button: 'Invia una mail',
    buttonIcon: 'right-circle',
  };

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _users: UserService
  ) {
    // this.getUserIdThroughService();
    // this.getUserTypeThroughService();
    this.getUserStuffGeneric();
  }

  ngOnInit() {}

  goTo() {
    this._router.navigate([`/app/${this.user_type}/home`]);
  }

  // getUserIdThroughService() {
  //   this._users.userId$.subscribe((userId) => {
  //     this.user_id = userId ?? '';
  //   });
  // }

  // getUserTypeThroughService() {
  //   this._users.userType$.subscribe((userType) => {
  //     this.user_type = userType ?? '';
  //   });
  // }

  getUserStuffGeneric() {
    this._users.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userStuff: any) => {
        this.user_id = userStuff.user.id ?? '';
        this.user_type = userStuff.user.type ?? '';
      });
  }
}
