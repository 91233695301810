@if(isLoading){
  <div class="loader-container">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
}

<nz-layout class="w-100 h-100">
  @if((current_route | async) && !(current_route | async)?.includes('auth')){
    <nz-sider class="sider" nzTheme="light">
      <app-sidebar></app-sidebar>
    </nz-sider>
  }

  <nz-layout>
    <nz-content>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
