<div class="d-xl-flex align-items-center px-5 py-3 d-none mb-12">
  <div class="d-flex justify-content-start align-items-center cursor-pointer" (click)="navigateTo('/home')">
    <img src="../../../assets/imgs/habito_logo.svg">
  </div>
  <div class="cursor-pointer p-2 px-3" (click)="navigateTo('/home')">
    <span nz-icon nzType="home" nzTheme="outline" class="icon"></span>
    <label class="ms-2 cursor-pointer">Home</label>
  </div>
  <div class="cursor-pointer p-2 px-3 " (click)="navigateToNews('/home')" >
    <span nz-icon nzType="read" nzTheme="outline" class="icon"></span>
    <label class="ms-2 cursor-pointer">News</label>
  </div>
  <div class="cursor-pointer p-2 px-3 flex-grow-1 bd-highlight">
    <span nz-dropdown nzTrigger="hover" [nzDropdownMenu]="menu">
      <span nz-icon nzType="profile" nzTheme="outline" class="icon"></span>
      <label class="mx-2 cursor-pointer">Catalogo</label>
      <span nz-icon nzType="down"></span>
    </span>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>1st menu item</li>
        <li nz-menu-item>2nd menu item</li>
        <li nz-menu-divider></li>
        <li nz-menu-item nzDisabled>disabled menu item</li>
        <li nz-submenu nzTitle="sub menu">
          <ul>
            <li nz-menu-item>3rd menu item</li>
            <li nz-menu-item>4th menu item</li>
          </ul>
        </li>
        <li nz-submenu nzDisabled nzTitle="disabled sub menu">
          <ul>
            <li nz-menu-item>3rd menu item</li>
            <li nz-menu-item>4th menu item</li>
          </ul>
        </li>
      </ul>
    </nz-dropdown-menu>

  </div>
  <div class="p-2 px-3 button-blue cursor-pointer" *ngIf="user_type=='locator'" (click)="navigateTo('/properties/new')">
    <label class="cursor-pointer">Aggiungi una proprietà</label>
    <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
  </div>
  <div class="p-2 px-3 cursor-pointer">
    <span nz-dropdown nzTrigger="hover" [nzDropdownMenu]="menuUser">
      <span nz-icon nzType="user" nzTheme="outline" class="icon"></span>
      <label class="mx-2 cursor-pointer"> Account </label>
      <span nz-icon nzType="down"></span>
    </span>
    <nz-dropdown-menu #menuUser="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="navigateTo('/user')">Area Personale</li>
        <li nz-menu-item (click)="logout()">Esci</li>
        <!--<li nz-menu-divider></li>
        <li nz-menu-item nzDisabled>disabled menu item</li>
        <li nz-submenu nzTitle="sub menu">
          <ul>
            <li nz-menu-item>3rd menu item</li>
            <li nz-menu-item>4th menu item</li>
          </ul>
        </li>
        <li nz-submenu nzDisabled nzTitle="disabled sub menu">
          <ul>
            <li nz-menu-item>3rd menu item</li>
            <li nz-menu-item>4th menu item</li>
          </ul>
        </li>-->
      </ul>
    </nz-dropdown-menu>
  </div>
</div>

<nav class="navbar bg-white fixed-top d-block d-lg-none mb-12">
  <div class="container justify-content-between">
    <a class="navbar-brand ms-3" (click)="navigateBack()" >
      <img src="../../../assets/imgs/habito_logo.svg">

    </a>

    <button class="btn btn-outline-secundary" type="button" aria-controls="offcanvasNavbar"
            (click)="changeDrawerStatus()">
      <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" -->
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- </div> -->
  </div>
</nav>

<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" nzTitle="Menu"
           [nzCloseOnNavigation]="true" (nzOnClose)="changeDrawerStatus()">
  <ng-container *nzDrawerContent>
    <nz-list nzItemLayout="horizontal" nzSize="large">
      <nz-list-item style="font-size:1.5rem" (click)="navigateTo('/home')">
        Home
      </nz-list-item>
      <nz-list-item style="font-size:1.5rem" (click)="navigateToNews('/home')">
        News
      </nz-list-item>
      <nz-list-item style="font-size:1.5rem" (click)="navigateTo('/user')">
        Area Personale
      </nz-list-item>
      <nz-list-item *ngIf="user_type == 'locator'" style="font-size:1.5rem" (click)="navigateTo('/properties/new')">
        Aggiungi una proprietà
      </nz-list-item>
      <nz-list-item style="font-size:1.5rem" (click)="logout()">
        Esci
      </nz-list-item>
    </nz-list>


    <!--<ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
      <li class="nav-item" (click)="navigateTo('/home')">
        <a class="nav-link active" aria-current="page">
          <span style="font-size:1.5rem">Home</span>
        </a>
      </li>
      <li class="nav-item" (click)="navigateToNews('/home')">
        <a class="nav-link active" aria-current="page">
          <span style="font-size:1.5rem">News</span>
        </a>
      </li>
      <li class="nav-item" (click)="navigateTo('/user')">
        <a class="nav-link active" aria-current="page">
          <span style="font-size:1.5rem">Area Personale</span>
        </a>
      </li>
      <li class="nav-item" (click)="navigateTo('/properties/new')">
        <a class="nav-link active" aria-current="page">
          <span style="font-size:1.5rem">Aggiungi una proprietà</span>
        </a>
      </li>
      <li class="nav-item" (click)="logout()">
        <a class="nav-link active" aria-current="page">
          <span style="font-size:1.5rem">Esci</span>
        </a>
      </li>
    </ul>-->
  </ng-container>
  <!-- </div> -->
</nz-drawer>
