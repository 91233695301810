import { Component, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/shared/helpers/user.service';
import { CookieService } from 'ngx-cookie-service';
import { LocationService } from 'src/app/shared/helpers/locations.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  user_type = localStorage.getItem('user_type') ?? 'requestor';
  isDrawerVisible: boolean = false;
  user_id =
    localStorage.getItem('user_type') === 'locator'
      ? '9ad2a763-df9a-460a-852c-a2a0a509f088'
      : '9ad2a763-df9a-460a-852c-a2a0a509f099';
  destroy$: Subject<void> = new Subject<void>();
  user_info: any;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _users: UserService,
    private _cookie: CookieService,
    private _locations: LocationService
  ) {
    if (_cookie.get('token')) {
      this.setUserVariables();
    }
  }

  navigateTo(path: string) {
    this._router.navigate([`app/${this.user_type}/${path}`]);
  }

  navigateToNews(path: string) {
    if (this._router.url.includes('home')) {
      location.hash = '#' + 'newsSection';
    } else {
      this._router.navigate([`app/${this.user_type}/${path}`]).then(() => {
        location.hash = '#' + 'newsSection';
      });
    }
    setTimeout(() => {
      this.removeHashAndRedirect(window.location.href);
    }, 1000);
  }

  removeHashAndRedirect(route: any): void {
    if (route.includes('#')) {
      const updatedRoute = route.split('#')[0];
      history.pushState(null, '', updatedRoute);
    } else {
      console.error('Route does not contain a "#" character.');
    }
  }

  logout() {
    let token = '';
    //TODO: chiamare l'api di logout per invalidare il Token
    this._users
      .logout(token)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response.status) {
          this._router.navigate(['auth/login']);
          localStorage.clear();
          this._cookie.deleteAll();
        }
      });
  }

  changeDrawerStatus() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }

  navigateBack() {
    this._router.navigate(['app/' + this.user_type + '/home']);
  }

  setUserVariables() {
    this._users.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response.user) {
          this.user_info = response.user;
          this.user_type = response.user.type;
          this.user_id = response.user.id;
        } else {
          this.testToken();
        }
      });
  }

  testToken() {
    this._users
      .getUserInfoThroughToken(this._cookie.get('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.user) {
          this.user_type = response.type;
          this.user_id = response.id;
        }
      });
  }

  testLocations() {
    this._locations
      .getLocationsGeneric()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log(response);
        }
      });
  }
}
