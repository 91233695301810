import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  isCardListView: boolean = window.innerWidth> 820;
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.updateView();
    }

    private updateView(): void {
      this.isCardListView = window.innerWidth > 820;
  }

}
