import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-propetyList',
  templateUrl: './propetyList.component.html',
  styleUrls: ['./propetyList.component.scss']
})
export class PropetyListComponent implements OnInit {
  isCardListView: boolean = window.innerWidth > 820;
  effect = 'scrollx';
  @Input() request?: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateView();
  }

  constructor() { }

  ngOnInit() {
  }

  private updateView(): void {
    this.isCardListView = window.innerWidth > 820;
  }

}
