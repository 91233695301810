import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  // private userId = new BehaviorSubject<string | null>(null);
  // private userType = new BehaviorSubject<string | null>(null);
  private user = new BehaviorSubject<any>(null);
  private cachedData: any;

  // userId$ = this.userId.asObservable();
  // userType$ = this.userType.asObservable();
  user$ = this.user.asObservable();

  constructor(private http: HttpClient, private _cookie: CookieService) {
    this.user$.subscribe({
      next: (value) => {
        if (value === null) {
          this.user$ = this.getUserInfoThroughToken(this._cookie.get('token'));
        } else {
          console.log('The value is not null:', value);
        }
      },
      error: (error) => {
        console.error('Error:', error);
      },
      complete: () => {
        console.log('Completed');
      },
    });

    // this.userType.next(localStorage.getItem('user_type'));
    // this.userId.next(localStorage.getItem('user_id'));
  }

  // setUserId(userId: string): void {
  //   this.userId.next(userId);
  //   localStorage.setItem('user_id', userId);
  // }

  // setUserType(userType: string): void {
  //   this.userType.next(userType);
  //   localStorage.setItem('user_type', userType);
  // }

  setUser(user: any): void {
    if (user) {
      this.user.next(user);
    } else {
      const test = this.getUserInfoThroughToken(localStorage.getItem('token'));
      this.user.next(test);
    }
  }

  /**
   * recupera una lista di utenti
   */
  getUsers(): Observable<any> {
    return this.http.get<any>(`${environment.api}/users`);
  }

  /**
   * recupera le info di un utente
   * @param id id della utente da recuperare
   */
  getUserInfo(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/users/${id}`);
  }

  postUserInfo(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/users/spid/info`, data);
  }

  editUser(id: string, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/users/${id}`, data);
  }

  getUserInfoThroughToken(token: any): Observable<any> {
    if (this.cachedData) {
      return this.cachedData;
    } else {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      return this.http
        .get<any>(`${environment.api}/user`, { headers })
        .pipe(tap((data) => (this.cachedData = data)));
    }
  }

  // logout rimozione cookie
  logout(token: string) {
    return this.http.post<any>(`${environment.api}/users/logout`, token);
  }
}
