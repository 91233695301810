import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}

  /**
   * recupera una lista di news
   */
  getNews(): Observable<any> {
    return this.http.get<any>(`${environment.api}/news`);
  }

  /**
   * recupera una lista di news utilizzando un feed rss
   */
  getNewsThroughFeed(guid: any = null): Observable<any> {
    return this.http.get<any>(`${environment.api}/feed/news?guid=${guid}`);
  }

  /**
   * recupera una news
   * @param id id della news da espandere
   */
  getNewsDetail(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/news/${id}`);
  }
}
