import { ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import { LoaderService } from './core/helpers/loader.service';
import { BaseComponent } from './shared/components/base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent implements OnInit {
  private loaderService = inject(LoaderService);
  private cdr = inject(ChangeDetectorRef);

  isLoading: boolean = false;

  override ngOnInit(): void {
    super.ngOnInit();

    this.loaderService.loading$.subscribe({
      next: value => {
        this.isLoading = value;
        this.cdr.detectChanges(); //serve per evitare l'errore ExpressionChangedAfterItHasBeenCheckedError
      }
    })
  }
}
