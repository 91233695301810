import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() routes : {
    icon?: string | null,
    label: string | null,
    url: string | null
  } [] = [];
}
