<div *ngIf="isCardListView">
  <div class="d-flex flex-column flex-xl-row justify-content-xl-between my-5">
    <div [ngClass]="{'order-1' : data?.imagePosition == 'left'}"
      class="d-flex flex-column justify-content-center d-xl-block">
      <img *ngIf="data?.titleImage" class="logo img-fluid" [src]="'../assets/imgs/'+data?.titleImage">
      <div *ngIf="data?.title" class="col-12" [innerHTML]="data?.title"></div>
      <div *ngIf="data?.subtitle" class="col-12" [innerHTML]="data?.subtitle"></div>
      <button class="pb-2 px-3 mt-5 btn btn-primary" *ngIf="data?.button" (click)="data?.buttonAction()">
        <label class="icon">{{data?.button}}</label>
        <span *ngIf="data && data.buttonIcon" nz-icon [nzType]="data.buttonIcon" class="icon"></span>
      </button>
    </div>
    <img *ngIf="data?.imagePath" class="immagine img-fluid" [src]="'../assets/imgs/'+data?.imagePath"
      [ngClass]="{'order-1' : data?.imagePosition == 'right'}">
  </div>
</div>

<div *ngIf="!isCardListView">
  <div class="d-flex flex-column justify-content-xl-between my-5">
    <div [ngClass]="{'order-1' : data?.imagePosition == 'left'}"
      class="d-flex flex-column justify-content-center d-xl-block">
      <img *ngIf="data?.titleImage" class="logo img-fluid" [src]="'../assets/imgs/'+data?.titleImage">
      <div *ngIf="data?.title" class="col-12" [innerHTML]="data?.title"></div>
      <div *ngIf="data?.subtitle" class="col-12" [innerHTML]="data?.subtitle"></div>

    </div>

    <div style="text-align: center;">
      <img *ngIf="data?.imagePath" class="immagine img-fluid" [src]="'../assets/imgs/'+data?.imagePath"
        [ngClass]="{'order-1' : data?.imagePosition == 'right'}">
    </div>


  </div>
  <div style="text-align: center;">
    <button class="pb-2 px-3 mt-5 btn btn-primary" *ngIf="data?.button" (click)="data?.buttonAction()">
      <label class="icon">{{data?.button}}</label>
      <span *ngIf="data && data.buttonIcon" nz-icon [nzType]="data.buttonIcon" class="icon"></span>
    </button>
  </div>
</div>
