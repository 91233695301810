import { Component, HostListener, Input } from '@angular/core';
import { Card } from '../../shared/models/card.model';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent {
  isCardListView: boolean = window.innerWidth > 820;

  @Input() elements: Card[] = [];
  @Input() title: string | null = null;
  @Input() contentType: string | null = null;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateView();
  }

  effect = 'scrollx';

  constructor() {}

  ngOnInit(): void {}

  private updateView(): void {
    this.isCardListView = window.innerWidth > 820;
  }

  getImagePath(element: any): string {
    if (this.contentType === 'news' && element.imagePath) {
      return element.imagePath;
    } else {
      return '../assets/imgs/' + element.imagePath;
    }
  }
}
