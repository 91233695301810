import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MomentPipe} from "../pipes/moment.pipe";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {NgZorroAntdModule} from "./ng-zorro-antd/ng-zorro-antd.module";
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { CardListComponent } from './card-list/card-list.component';
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {RouterLink} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { RequestSuccessfullyComponent } from '../modules/application/components/requestSuccessfully/requestSuccessfully/requestSuccessfully.component';
import { PropetyListComponent } from './propetyList/propetyList.component';
import { DatatableComponent } from './datatable/datatable.component';


@NgModule({
  declarations: [
    CardListComponent,
    FooterComponent,
    HeaderComponent,
    JumbotronComponent,
    MomentPipe,
    BreadcrumbComponent,
    NewsDetailComponent,
    RequestSuccessfullyComponent,
    PropetyListComponent,
    DatatableComponent,
    
  ],
  exports: [
    CardListComponent,
    FooterComponent,
    HeaderComponent,
    JumbotronComponent,
    MomentPipe,
    NgZorroAntdModule,
    BreadcrumbComponent,
    ReactiveFormsModule,
    PropetyListComponent,
    DatatableComponent
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    RouterLink,
    ReactiveFormsModule,
  ]
})
export class ComponentsModule { }
