<div class="footer-container" *ngIf="isCardListView">
  <div class=" d-xl-block">
    <div class="divider-top justify-content-center"></div>

    <div class="logo-list">
      <div class="d-flex justify-content-start align-items-center partners col-xl-8">
        <img src="../../../assets/imgs/habito_logo.svg" class="img-fluid">
        <div class="d-flex flex-row justify-content-between flex-start align-items-center col">
          <img src="../../../assets/imgs/eht_logo.svg" class="img-fluid" />
          <img src="../../../assets/imgs/logo_comune.svg" class="img-fluid" />
          <img src="../../../assets/imgs/regione_sicilia_logo.svg" class="img-fluid" />
        </div>
      </div>
      <div class="divider-box col-xl-1">
        <div class="divider"></div>
      </div>
      <div class="col-xl-3"><a href="#">Dichiarazione di Accessibilità</a></div>
    </div>

    <div class="blue-box">
      <ul>
        <li><a href="#">Media Policy</a></li>
        <li><a href="#">Note Legali</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#">Mappa del sito</a></li>
      </ul>
    </div>
  </div>
</div>


<div class="footer-container" *ngIf="!isCardListView">
  <div class=" d-xl-block">

    <div class="logo-list-phone">
      <div class=" partners-phone">
        <img src="../../../assets/imgs/habito_logo.svg">
        <div class="partners-cont">
          <span><img src="../../../assets/imgs/eht_logo.svg" class="img-fluid" /></span>
          <span><img src="../../../assets/imgs/logo_comune.svg" class="img-fluid" /></span>
          <span><img src="../../../assets/imgs/regione_sicilia_logo.svg" class="img-fluid" /></span>
        </div>
      </div>

      <div class="col-xl-3 Dichiarazione"><a href="#">Dichiarazione di Accessibilità</a></div>
    </div>

    <div class="blue-box-phone">
      <ul>
        <li><a href="#">Media Policy</a></li>
        <li><a href="#">Note Legali</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#">Mappa del sito</a></li>
      </ul>
    </div>
  </div>
</div>
