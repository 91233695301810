import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { FormattedResponse } from '../models/formatted-response.model';
import { User } from '../models/user.model';
import {HttpContextConfig} from "../models/http-context-config.model";
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);

  login(credentials: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/login`, credentials, { withCredentials: true });
  }

  logout() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/logout`);
  }

  me() {
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { withCredentials: true });
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { context: context });
  }

  getTokenExpiration(token: string): Date | null {
    try {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken.exp === undefined) {
        return null;
      }

      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decodedToken.exp);

      return expirationDate;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }
}
